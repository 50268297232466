import React from 'react'
import { Link } from 'gatsby'

// import Image from './Image'
import { graphql, StaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import './PostCard.css'

const PostCard = ({
  featuredImage,
  title,
  excerpt,
  slug,
  categories = [],
  className = '',
  ...props
}) => {
  const map = {
    'campaign-creators-gMsnXqILjp4-unsplash.webp': 'one',
    'photo-1514428631868-a400b561ff44.webp': 'two',
    'lazar-gugleta-Ub4CggGYf2o-unsplash.webp': 'seven'
  }
  console.log('looking up bg image: ', featuredImage, ' > ', map[featuredImage])
  return (
    <StaticQuery
      query={graphql`
        query {
          one: file(
            relativePath: { eq: "campaign-creators-gMsnXqILjp4-unsplash.webp" }
          ) {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          two: file(
            relativePath: { eq: "photo-1514428631868-a400b561ff44.webp" }
          ) {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          seven: file(
            relativePath: { eq: "lazar-gugleta-Ub4CggGYf2o-unsplash.webp" }
          ) {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={data => {
        const imageData =
          data[map[featuredImage]] === undefined
            ? data[map['lazar-gugleta-Ub4CggGYf2o-unsplash.webp']]
                .childImageSharp.fluid
            : data[map[featuredImage]].childImageSharp.fluid

        return (
          <Link to={slug} className={`PostCard ${className}`}>
            <BackgroundImage
              className={`PostCard--Image relative`}
              fluid={imageData}
            />
            <div className="PostCard--Content">
              {title && <h3 className="PostCard--Title">{title}</h3>}
              <div className="PostCard--Category">
                {categories && categories.map(cat => cat.category).join(', ')}
              </div>
              {excerpt && <div className="PostCard--Excerpt">{excerpt}</div>}
            </div>
          </Link>
        )
      }}
    />
  )
}

export default PostCard
